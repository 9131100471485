import {
  DataPagination,
  DataTable,
  LoadingOverlay,
  PageContent,
  ValueWithLabel
} from "@nbp/dnafe-material-ui/dist/components";
import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEvent} from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import moment from "moment";
import {useForm} from "react-hook-form";
import {Alert} from "@mui/material";
import {FORMAT_DATE_SERVER} from "../constants/date";
import {usePageTitle} from "../hooks/usePageTitle";
import {getAnalysisTotal, useAnalysisTotal, useAnalysisTotalLoading} from "../hooks/analisys";
import PageHeader from "../components/Layout/PageHeader/PageHeader";
import AnalysisDateRangeFilter from "../components/analysis/AnalysisDateRangeFilter";
import {hasPermission} from "../hooks/auth";
import {PERMISSION} from "../constants/permission";
import {
  getRegistrationAuthorityAnalysisPaged,
  useRegistrationAuthorityAnalysisPaged,
  useRegistrationAuthorityAnalysisPagedLoading
} from "../hooks/registrationAuthority";
import {
  PageRegistrationAuthorityAnalyticPagingDto,
  RegistrationAuthorityAnalyticPagingDto,
  RegistrationAuthorityResponse
} from "../api";
import {usePagedTable} from "@nbp/dnafe-material-ui/dist/hooks/usePagedTable";
import {DataTableColumn} from "@nbp/dnafe-material-ui/dist/components/DataTable/DataTable";
import {LINK_ANALYSIS} from "../constants/navigate";
import CertificateTypeSelect from "../components/analysis/CertificateTypeSelect";
import {CERTIFICATE_ANY, CERTIFICATE_TRUSTED} from "../constants/analysis";
import SearchField from "../components/SearchField/SearchField";

const getDefaultValues = () => ({
  startDate: moment().subtract(1, "month").startOf("month").format(FORMAT_DATE_SERVER),
  endDate: moment().startOf("month").subtract(1, "day").format(FORMAT_DATE_SERVER),
  certificateTypes: CERTIFICATE_TRUSTED
});

const AnalysisPage = () => {
  usePageTitle("menu.analysis");
  const {t, i18n: {language}} = useTranslation();

  const defaultValues = getDefaultValues();
  const analysisTotalLoading = useAnalysisTotalLoading();
  const analysisTotal = useAnalysisTotal();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const ref = useRef<boolean>(false);

  const {control, getValues, setValue, trigger} = useForm({defaultValues});

  const loader = useEvent(async () => {
    setError(false);
    try {
      const valid = await trigger().catch(console.error);
      if (valid) {
        const {startDate, endDate, certificateTypes} = getValues();
        const certificateType = certificateTypes === CERTIFICATE_ANY ? "" : certificateTypes;
        await getRegistrationAuthorityAnalysisPaged({
          size: pageSize, page, sort: sortValue, search, startDate, endDate, certificateTypes: certificateType
        }).catch(console.error);
        return await getAnalysisTotal({
          startDate,
          endDate,
          certificateTypes: certificateType
        }).catch(console.error);
      }
    } catch (error) {
      console.error(error);
    }
    setError(true);
    return null;
  });

  const setDateRange = useEvent((startDate: string, endDate: string) => {
    setValue("startDate", startDate);
    setValue("endDate", endDate);
    loader();
  });

  const handleRowClick = useEvent(({id}: RegistrationAuthorityResponse) => {
    navigate(LINK_ANALYSIS + "/" + id);
  });

  useEffect(() => {
    if (!ref.current) {
      loader();
      ref.current = true;
    }
  }, []);

  const {page, setPage, pageSize, search, setSearch, pages, tableRows, sort, onSort} =
    usePagedTable<PageRegistrationAuthorityAnalyticPagingDto>({
      useData: useRegistrationAuthorityAnalysisPaged,
      useLoading: useRegistrationAuthorityAnalysisPagedLoading,
      loader,
      defaultSort: {field: "createdAt", desc: true}
    });

  const sortValue = sort?.field ? [sort?.field, sort?.desc ? "desc" : "asc"].join(",") : undefined;

  const columns: DataTableColumn<RegistrationAuthorityAnalyticPagingDto>[] = useMemo(() => [
    {
      name: "name",
      title: t("registrationAuthority.name"),
      sortable: true
    },
    {
      name: "numberOfSignatures",
      title: t("analysis.numberOfSignatures"),
      sortable: true
    },
    {
      name: "numberOfRevocations",
      title: t("analysis.numberOfRevocations"),
      sortable: true
    }
  ].filter(item => !!item), [language]);

  return (
    <LoadingOverlay className="AnalysisPage" loading={analysisTotalLoading}>
      <PageHeader title={t("menu.analysis")}>
        <CertificateTypeSelect control={control} onChange={loader}/>
      </PageHeader>
      <PageContent>
        <div className="filter-row flex-row flex-wrap flex-gap margin-bottom-m">
          <AnalysisDateRangeFilter
            setDateRange={setDateRange} control={control} defaultValues={defaultValues} getValues={getValues}
            onChange={loader}
          />
        </div>
        {error && <Alert className="margin-bottom-m" color="error">
          {t("analysis.filterInvalid")}
        </Alert>}
        <div className="content-block">
          <div className="flex-row flex-gap flex-wrap flex-columns-3">
            <div className="flex-1">
              <ValueWithLabel
                label={t("analysis.numberOfSignatures")} value={String(analysisTotal?.numberOfSignatures ?? "")}
                noEmptyText={analysisTotalLoading}
              />
            </div>
            <div className="flex-1">
              <ValueWithLabel
                label={t("analysis.numberOfRevocations")} value={String(analysisTotal?.numberOfRevocations ?? "")}
                noEmptyText={analysisTotalLoading}
              />
            </div>
          </div>
        </div>
      </PageContent>
      <PageHeader subTitle={t("analysis.registrationAuthorities")}>
        <SearchField placeholder={t("analysis.search")} onSearch={setSearch} className="margin-bottom-s"/>
      </PageHeader>
      <PageContent>
        <DataTable
          data={tableRows} columns={columns}
          onRowClick={hasPermission(PERMISSION.REGISTRATION_AUTHORITY_READ) ? handleRowClick : undefined}
          sort={sort} onSort={onSort} emptyText={t("main.empty")}
        />
        {!!pages && <DataPagination pages={pages} onChange={setPage} value={page}/>}
      </PageContent>
    </LoadingOverlay>
  );
};

export default memo(AnalysisPage);
