import {GlobalState} from "@nbp/dnafe-material-ui/dist/hooks/GlobalState";
import {getGlobalStateHookSetterGetter, getRequestHooks} from "@nbp/dnafe-material-ui/dist/hooks/globalStateHooks";
import {PagedProps, PagedResponse} from "@nbp/dnafe-material-ui/dist/models/paged";
import {ROWS_PER_PAGE} from "@nbp/dnafe-material-ui/dist/constants/usability";
import {
  analysisRegistrationAuthoritiesGet,
  PageRegistrationAuthorityAnalyticPagingDto,
  registrationAuthoritiesGet,
  registrationAuthoritiesIdDelete,
  RegistrationAuthoritiesIdDeleteArgs,
  registrationAuthoritiesIdGet,
  RegistrationAuthoritiesIdGetArgs,
  registrationAuthoritiesIdNamesGet,
  RegistrationAuthoritiesIdNamesGetArgs,
  registrationAuthoritiesPost,
  registrationAuthoritiesRegistrationAuthorityIdAdminsGet,
  RegistrationAuthoritiesRegistrationAuthorityIdAdminsGetArgs,
  registrationAuthoritiesRegistrationAuthorityIdAdminsIdDelete,
  RegistrationAuthoritiesRegistrationAuthorityIdAdminsIdDeleteArgs,
  registrationAuthoritiesRegistrationAuthorityIdAdminsPost,
  RegistrationAuthoritiesRegistrationAuthorityIdAdminsPostArgs,
  RegistrationAuthorityAdminRequest,
  RegistrationAuthorityAdminResponse,
  RegistrationAuthorityNameResponseDto,
  RegistrationAuthorityRequest,
  RegistrationAuthorityResponse
} from "../api";
import {userMeGlobalState} from "./user";

interface RegistrationAuthorityPagedProps extends PagedProps {
  sort?: string;
  search?: string;
}

export const [getRegistrationAuthorityPaged, useRegistrationAuthorityPagedLoading, useRegistrationAuthorityPaged] =
  getRequestHooks<PagedResponse<RegistrationAuthorityResponse>, RegistrationAuthorityPagedProps>(
    ({sort, page = 0, size = ROWS_PER_PAGE, search} = {}) =>
      registrationAuthoritiesGet({page, size, sort: sort ? [sort] : undefined, search}) as any
  );

interface RegistrationAuthorityAnalysisPagedProps extends PagedProps {
  sort?: string;
  search?: string;
  startDate?: string;
  endDate?: string;
  certificateTypes?: string;
}

export const [getRegistrationAuthorityAnalysisPaged, useRegistrationAuthorityAnalysisPagedLoading, useRegistrationAuthorityAnalysisPaged] =
  getRequestHooks<PagedResponse<PageRegistrationAuthorityAnalyticPagingDto>, RegistrationAuthorityAnalysisPagedProps>(
    ({sort, page = 0, size = ROWS_PER_PAGE, search, startDate, endDate, certificateTypes} = {}) =>
      analysisRegistrationAuthoritiesGet({
        page,
        size,
        sort: sort ? [sort] : undefined,
        search,
        startDate,
        endDate,
        certificateTypes
      }) as any
  );

const registrationAuthorityPopoverMenuState: GlobalState<{
  anchor: any,
  data?: RegistrationAuthorityResponse
}> = new GlobalState(null);
export const [useRegistrationAuthorityMenuPopover, setRegistrationAuthorityMenuPopover] = getGlobalStateHookSetterGetter(registrationAuthorityPopoverMenuState);

export const [createRegistrationAuthority, useCreateRegistrationAuthorityLoading] =
  getRequestHooks<RegistrationAuthorityResponse, RegistrationAuthorityRequest>(registrationAuthoritiesPost);

export const [getRegistrationAuthoritiesIdNames, useRegistrationAuthoritiesIdNamesLoading, useRegistrationAuthoritiesIdNamesData] =
  getRequestHooks<RegistrationAuthorityNameResponseDto, RegistrationAuthoritiesIdNamesGetArgs>(registrationAuthoritiesIdNamesGet);

export const [deleteRegistrationAuthority, useDeleteRegistrationAuthorityLoading] =
  getRequestHooks<any, RegistrationAuthoritiesIdDeleteArgs>(registrationAuthoritiesIdDelete);
export const [getRegistrationAuthority, useGetRegistrationAuthorityLoading, useGetRegistrationAuthorityData] =
  getRequestHooks<RegistrationAuthorityResponse, RegistrationAuthoritiesIdGetArgs>(registrationAuthoritiesIdGet);

const registrationAuthorityAdminsLoadedForState: GlobalState<string> = new GlobalState(null);
export const [useRegistrationAuthorityAdminsLoadedFor, setRegistrationAuthorityAdminsLoadedFor] = getGlobalStateHookSetterGetter(registrationAuthorityAdminsLoadedForState);

export const [getRegistrationAuthorityAdmins, useRegistrationAuthorityAdminsLoading, useRegistrationAuthorityAdmins] =
  getRequestHooks<PagedResponse<RegistrationAuthorityAdminResponse>, RegistrationAuthoritiesRegistrationAuthorityIdAdminsGetArgs>(
    (props) => registrationAuthoritiesRegistrationAuthorityIdAdminsGet(props) as any
  );

interface RegistrationAuthorityCreateAdminArgs {
  args: RegistrationAuthoritiesRegistrationAuthorityIdAdminsPostArgs;
  body: RegistrationAuthorityAdminRequest;
}

export const [createRegistrationAuthorityAdmin, useCreateRegistrationAuthorityAdminLoading, useCreateRegistrationAuthorityAdmin] =
  getRequestHooks<RegistrationAuthorityAdminResponse, RegistrationAuthorityCreateAdminArgs>(
    ({args, body}) => registrationAuthoritiesRegistrationAuthorityIdAdminsPost(args, body)
  );

export const [deleteRegistrationAuthorityAdmin, useDeleteRegistrationAuthorityAdminLoading, useDeleteRegistrationAuthorityAdmin] =
  getRequestHooks<any, RegistrationAuthoritiesRegistrationAuthorityIdAdminsIdDeleteArgs>(registrationAuthoritiesRegistrationAuthorityIdAdminsIdDelete);

export const getMyRegistrationAuthority = (registrationAuthorityId: string) => {
  const userMe = userMeGlobalState.getValue()?.data;
  return userMe?.registrationAuthorityAdmins?.find(item => item.registrationAuthority.id === registrationAuthorityId);
};

export const hasRegistrationAuthorityAccess = (registrationAuthorityId: string) => !!getMyRegistrationAuthority(registrationAuthorityId);

const registrationAuthorityActionsPopoverState = new GlobalState<{ anchor?: any }>({});
export const [useRegistrationAuthorityActionsPopover, setRegistrationAuthorityActionsPopover, getRegistrationAuthorityActionsPopover] = getGlobalStateHookSetterGetter(registrationAuthorityActionsPopoverState);
