import React, {memo, useCallback, useEffect, useId, useMemo, useState} from "react";
import PageHeader from "../components/Layout/PageHeader/PageHeader";
import {useTranslation} from "react-i18next";
import {ConfirmationDialog, DataPagination, PageContent} from "@nbp/dnafe-material-ui/dist/components";
import {usePageTitle} from "../hooks/usePageTitle";
import {useNavigate, useParams} from "react-router-dom";
import {getMyRegistrationAuthority} from "../hooks/registrationAuthority";
import {LINK_ROOT} from "../constants/navigate";
import {useUserMeData} from "../hooks/user";
import {
  deleteImportInstitutionsTableData,
  getImportInstitutionsTableData,
  initImportLoop,
  setImportInstitutionsProgress,
  setImportInstitutionsTableData,
  useImportInstitutionsTableData
} from "../hooks/institution";
import {InstitutionBaseResponse} from "../api";
import {getInstitutionAddress} from "../helpers/institution";
import DataTable, {DataTableColumn} from "@nbp/dnafe-material-ui/dist/components/DataTable/DataTable";
import {Alert, Button, IconButton, Tooltip} from "@mui/material";
import {useEvent} from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import {loadUserFile, parseCSV} from "../helpers/file";
import {useModal} from "mui-modal-provider";
import InstitutionImportMappingDialog from "../components/Institution/InstitutionImportMappingDialog";
import {SimpleDialogProps} from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import {AutocompleteOption} from "../models/form";
import {ITEMS_PER_PAGE} from "../constants/usability";
import {InstitutionMenuPopover} from "../components/Institution/InstitutionMenuPopover";
import InstitutionImportDetailsDialog from "../components/Institution/InstitutionImportDetailsDialog";
import {InstitutionRequestData} from "../models/institution";
import {
  ADDRESS_REQUIRED_FIELDS,
  INSTITUTION_COUNTRY_CODE,
  INSTITUTION_INVALID_DUPLICATE,
  INSTITUTION_INVALID_REQUIRED,
  INSTITUTION_REQUIRED_FIELDS
} from "../constants/institution";
import InstitutionImportProgressDialog from "../components/Institution/InstitutionImportProgressDialog";
import {showError} from "@nbp/dnafe-material-ui/dist/hooks/snackbar";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const inputFileStyle = {visibility: "hidden", position: "absolute"} as any;

const ImportInstitutionsPage = () => {
  usePageTitle("menu.importInstitutions");
  const {registrationAuthorityId} = useParams();
  const {t, i18n: {language}} = useTranslation();
  const inputFileId = useId();
  const userMe = useUserMeData();
  const navigate = useNavigate();
  const [importData, setImportData] = useState<{ [key: string]: string }[]>([]);
  const tableData = useImportInstitutionsTableData() ?? [];
  const [filtered, setFiltered] = useState(false);
  const {showModal} = useModal();
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (tableData?.length && tableData[0].registrationAuthorityId !== registrationAuthorityId) {
      resetData();
    }
    setImportInstitutionsProgress(null);
  }, []);

  const [validationErrors, isRequiredMissing, hasSameName] = useMemo(() => {
    const usedNames: any = {};
    let isRequiredMissing = false;
    let hasSameName = false;
    const validationErrors = tableData.map((item) => {
      let error: string = null;
      let data: any = null;
      const missingField = INSTITUTION_REQUIRED_FIELDS.find(field => !item[field]);
      if (missingField) {
        error = INSTITUTION_INVALID_REQUIRED;
        isRequiredMissing = true;
        data = missingField;
      } else {
        const address = (item?.addresses ?? [])[0] ?? {};
        const missingAddressField = ADDRESS_REQUIRED_FIELDS.find(field => !address[field]);
        if (missingAddressField) {
          error = INSTITUTION_INVALID_REQUIRED;
          isRequiredMissing = true;
          data = missingAddressField;
        }
      }
      if (usedNames[item.name]) {
        error = INSTITUTION_INVALID_DUPLICATE;
        hasSameName = true;
        data = item.name;
        usedNames[item.name]++;
      } else {
        usedNames[item.name] = 1;
      }
      return error ? {id: item.id, error, data} : null;
    }).filter(item => !!item);
    return [validationErrors, isRequiredMissing, hasSameName];
  }, [tableData]);


  const filteredData = useMemo(() => {
    if (validationErrors.length && filtered) {
      const ids: any = {};
      validationErrors.map(item => item.id).forEach(id => {
        ids[id] = true;
      });
      return [...tableData].filter(item => ids[item.id]);
    }
    return tableData;
  }, [tableData, validationErrors, filtered]);

  const pages = Math.ceil(filteredData?.length / ITEMS_PER_PAGE);
  const pagedData = useMemo(
    () => filteredData.slice(page * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE + ITEMS_PER_PAGE),
    [filteredData, page]
  );

  const filterWithErrors = useEvent(() => {
    setPage(0);
    setFiltered(true);
  });

  const handleRemoveWithErrors = useEvent(() => {
    const ids: any = {};
    validationErrors.map(item => item.id).forEach(id => {
      ids[id] = true;
    });
    setImportInstitutionsTableData([...getImportInstitutionsTableData()].filter(
      item => !ids[item.id]
    ));
  });

  const removeWithErrors = useEvent(() => {
    showModal((props: SimpleDialogProps) => (
      <ConfirmationDialog
        text={t("institutions.removeWithErrorsConfirmation", {amount: tableData.length})} confirmText={t("main.remove")}
        title={t("main.confirmation")} cancelText={t("main.cancel")} onConfirm={handleRemoveWithErrors} {...props}
      />
    ));
  });

  const filterAll = useEvent(() => {
    setPage(0);
    setFiltered(false);
  });

  const registrationAuthority = useMemo(() => getMyRegistrationAuthority(registrationAuthorityId), [registrationAuthorityId, userMe]);

  useEffect(() => {
    if (userMe) {
      if (!registrationAuthority) {
        navigate(LINK_ROOT);
      }
    }
  }, [registrationAuthority, userMe]);

  const mapRecord = (record: { [key: string]: string }, fieldsMap: {
    [key: string]: AutocompleteOption[]
  }, index: number) => {
    let result: { [key: string]: string | number | { [key: string]: string | number } } = {
      countryCode: INSTITUTION_COUNTRY_CODE,
      registrationAuthorityId
    };
    Object.keys(fieldsMap).forEach(fieldName => {
      const mappings = fieldsMap[fieldName];
      if (mappings) {
        let value = "";
        mappings.forEach(mapping => {
          if (mapping.id !== undefined) {
            value += record[mapping.label];
          } else {
            value += mapping.label;
          }
        });
        result[fieldName] = value;
      }
    });
    result = new InstitutionRequestData(result) as any;
    result.id = index;
    return result;
  };

  const onMapping = useEvent((fieldsMap: { [key: string]: AutocompleteOption[] }) => {
    const tableData = importData.map((record, index) => mapRecord(record, fieldsMap, index));
    setImportInstitutionsTableData(tableData);
    setPage(0);
    setImportData([]);
  });

  const showImportMappingDialog = useEvent((fields: string[]) => showModal((props: SimpleDialogProps) => (
    <InstitutionImportMappingDialog {...props} fields={fields} onChange={onMapping}/>
  )));

  const handleUpload = useEvent(() => {
    document.getElementById(inputFileId).click();
  });

  const onFileSelected = useEvent((event: Event) => {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file) {
      return;
    }
    (document.getElementById(inputFileId) as HTMLInputElement).value = "";
    loadUserFile(file).then((content) => {
      const {data, fields} = parseCSV(content);
      setImportData(data);
      showImportMappingDialog(fields);
    }).catch(error => {
      console.error(error);
      showError(t("institutions.uploadError"));
    });
  });

  const handleImport = useEvent(() => {
    initImportLoop([...tableData]);
    showModal((props: SimpleDialogProps) => (<InstitutionImportProgressDialog {...props} />));
  });

  const showImportConfirmation = useEvent(() => {
    if (!pagedData.length) {
      showError(t("institutions.noInstitutionsError"));
      return;
    }
    if (isRequiredMissing || hasSameName) {
      showError(t("institutions.validationError"));
      return;
    }
    showModal((props: SimpleDialogProps) => (
      <ConfirmationDialog
        text={t("institutions.importConfirmation", {amount: tableData.length})} confirmText={t("main.import")}
        title={t("main.confirmation")} cancelText={t("main.cancel")} onConfirm={handleImport} {...props}
      />
    ));
  });

  const showResetConfirmation = useEvent(() => !!pagedData.length && showModal((props: SimpleDialogProps) => (
    <ConfirmationDialog
      text={t("institutions.resetConfirmation", {amount: tableData.length})} confirmText={t("main.reset")}
      title={t("main.confirmation")} cancelText={t("main.cancel")} onConfirm={resetData} {...props}
    />
  )));

  const resetData = useEvent(() => setImportInstitutionsTableData([]));

  const changeHandler = useEvent((id: number, data: InstitutionRequestData) => {
    setImportInstitutionsTableData([...tableData].map(item => {
      if (item.id !== id) {
        return item;
      }
      return {...item, ...data};
    }));
  });

  const deleteHandler = useEvent((id: number) => deleteImportInstitutionsTableData(id, registrationAuthorityId));

  const showEditInstitutionDialog = useEvent((data: InstitutionBaseResponse) => showModal((props: SimpleDialogProps) => (
    <InstitutionImportDetailsDialog
      {...props}
      onChange={changeHandler}
      onUpdate={() => deleteHandler(data.id)}
      data={data}
      registrationAuthorityId={registrationAuthorityId}
    />
  )));

  const DataTableActionsColumnTemplate = useCallback((row: InstitutionBaseResponse) => (
    <Tooltip title={t("main.remove")}>
      <IconButton color="primary" size="small" onClick={event => {
        event.stopPropagation();
        deleteHandler(row.id);
      }}><DeleteOutlineIcon/></IconButton>
    </Tooltip>
  ), [language]);

  const getRowClass = useCallback((data: any) => {
    const error = validationErrors.find(item => item.id === data?.id);
    return error ? "row-invalid" : "";
  }, [validationErrors]);

  const columns: DataTableColumn<InstitutionBaseResponse>[] = useMemo(() => [
    {
      name: "name",
      title: t("institutions.name")
    },
    {
      name: "type",
      title: t("institutions.type")
    },
    {
      name: "countryCode",
      title: t("institutions.address"),
      template: (row: InstitutionBaseResponse) => getInstitutionAddress(row, t)
    },
    {
      name: "actions",
      className: "action-column",
      headerClassName: "action-column-header",
      title: t("main.actions"),
      template: DataTableActionsColumnTemplate
    }
  ], [language]);

  return (
    <div className="ImportInstitutionsPage">
      <PageHeader
        title={registrationAuthority?.registrationAuthority?.name ?? t("menu.registrationAuthority")}
        subTitleMuted={t("menu.registrationAuthority")}
      />
      <PageHeader subTitle={t("institutions.importTitle") + (tableData?.length ? ` (${tableData?.length})` : "")}/>
      <PageContent>
        {!!validationErrors.length && <Alert color="error" className="margin-bottom-s" icon={<ErrorIcon/>}>
          <div className="flex-row flex-gap flex-wrap">
            {!!isRequiredMissing && <span>{t("error.isRequiredMissing")}</span>}
            {!!hasSameName && <span>{t("error.hasSameName")}</span>}
            {!filtered && <Button variant="text" size="small" className="padding-0" onClick={filterWithErrors}>
              {t("institutions.showWithErrors")}
            </Button>}
            {!!filtered && <Button variant="text" size="small" className="padding-0" onClick={filterAll}>
              {t("institutions.showAll")}
            </Button>}
            <Button variant="text" size="small" className="padding-0" onClick={removeWithErrors}>
              {t("institutions.removeWithErrors")}
            </Button>
            <span/>
          </div>
        </Alert>}
        <DataTable
          data={pagedData} columns={columns} getRowClass={getRowClass} onRowClick={showEditInstitutionDialog}
          empty={
            <div className="text-center">
              <Button onClick={handleUpload} variant="text" color="primary" startIcon={<FileUploadOutlinedIcon/>}>
                {t("institutions.uploadCSV")}
              </Button>
              <input type="file" id={inputFileId} accept=".csv" onChange={onFileSelected} style={inputFileStyle}/>
            </div>
          }
        />
        <DataPagination pages={pages || 0} onChange={setPage} value={page}/>
        <div className="margin-top-s flex-row flex-wrap gap-m">
          <div className="flex-auto"></div>
          <Button startIcon={<CloseOutlinedIcon/>} onClick={showResetConfirmation} variant="outlined">
            {t("main.reset")}
          </Button>
          <Button onClick={showImportConfirmation} variant="contained" startIcon={<CheckCircleOutlinedIcon/>}>
            {t("institutions.applyImport")}
          </Button>
        </div>
      </PageContent>
      <InstitutionMenuPopover onDelete={deleteHandler}/>
    </div>
  );
};

export default memo(ImportInstitutionsPage);
