import {Button, IconButton, Popover} from "@mui/material";
import React, {FC, memo, useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useEvent} from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import {BaseDialog, ConfirmationDialog, DataPagination, DataTable} from "@nbp/dnafe-material-ui/dist/components";
import {SimpleDialogProps} from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import {InstitutionBaseResponse} from "../../api";
import {
  getInstitutionsPaged,
  mergeInstitutions,
  useInstitutionPaged,
  useInstitutionPagedLoading,
  useMergeInstitutionsLoading
} from "../../hooks/institution";
import {usePagedTable} from "@nbp/dnafe-material-ui/dist/hooks/usePagedTable";
import {getInstitutionAddress} from "../../helpers/institution";
import {DataTableColumn} from "@nbp/dnafe-material-ui/dist/components/DataTable/DataTable";
import {OperatingStatusTemplate} from "./InstitutionTemplates";
import "./InstitutionMergeDialog.scss";
import SearchField from "../SearchField/SearchField";
import {useModal} from "mui-modal-provider";
import InstitutionsMergeToNewDialog from "./InstitutionsMergeToNewDialog";
import {showSuccess} from "@nbp/dnafe-material-ui/dist/hooks/snackbar";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface InstitutionMergeDialogProps extends SimpleDialogProps {
  data: InstitutionBaseResponse;
  onMergeToNew: (data: InstitutionBaseResponse) => void;
  onMerge: () => void;
}

const InstitutionMergeDialog: FC<InstitutionMergeDialogProps> = (
  {
    onClose, open, data, onMergeToNew, onMerge
  }
) => {
  const {t, i18n: {language}} = useTranslation();
  const [selected, setSelected] = useState<InstitutionBaseResponse>(null);
  const registrationAuthorityId = data?.registrationAuthority?.id;
  const institutionPagedLoading = useInstitutionPagedLoading();
  const mergeInstitutionsLoading = useMergeInstitutionsLoading();
  const {showModal} = useModal();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    loader();
  }, []);

  const onHelpClick = useEvent((event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget));

  const closeHelpPopover = useEvent(() => setAnchorEl(null));

  const loader = useEvent(async () => {
    setSelected(null);
    await getInstitutionsPaged({
      size: pageSize, page, search, sort: sortValue, registrationAuthorityId
    }).catch(console.error);
  });

  const onInstitutionSelect = useEvent(async (institution: InstitutionBaseResponse) => {
    setSelected(institution.id !== data?.id ? institution : null);
  });

  const getRowClass = useCallback((institution: InstitutionBaseResponse) => {
    if (institution?.id === data?.id) {
      return "disabled";
    }
    if (institution?.id === selected?.id) {
      return "selected";
    }
    return null;
  }, [selected]);

  const {page, setPage, pageSize, search, setSearch, pages, tableRows, sort, onSort} =
    usePagedTable<InstitutionBaseResponse>({
      useData: useInstitutionPaged,
      useLoading: useInstitutionPagedLoading,
      loader,
      defaultSort: {field: "name", desc: false}
    });

  const sortValue = sort?.field ? [sort?.field, sort?.desc ? "desc" : "asc"].join(",") : undefined;

  const mergeToCurrent = useEvent(() => {
    mergeInstitutions({
      registrationAuthorityId,
      idOfInstitutionToKeep: data?.id,
      moveAdminsToNewInstitution: true,
      moveIssuersToNewInstitution: true,
      idsToMerge: [data?.id, selected?.id]
    }).then(() => {
      showSuccess(t("institution.merge.mergeToCurrentSuccess"));
      onClose();
      onMerge();
    }).catch(console.error);
  });

  const showMergeToCurrentConfirmation = useEvent(() => showModal((props: SimpleDialogProps) => (
    <ConfirmationDialog
      text={t("institution.merge.mergeToCurrentConfirmation")} confirmText={t("institution.merge.mergeToCurrent")}
      title={t("main.confirmation")} cancelText={t("main.cancel")}
      onConfirm={mergeToCurrent} {...props}
    />
  )));

  const onMergedToNew = useEvent((institution: InstitutionBaseResponse) => {
    onMergeToNew(institution);
    onClose();
  });

  const showMergeToNewDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <InstitutionsMergeToNewDialog
      data={data} selectedId={selected.id} registrationAuthorityId={registrationAuthorityId}
      onMerge={onMergedToNew} {...props}
    />
  )));

  const columns: DataTableColumn<InstitutionBaseResponse>[] = useMemo(() => [
    {
      name: "name",
      title: t("institutions.name"),
      sortable: true
    },
    {
      name: "operatingStatus",
      title: t("institutions.operatingStatus"),
      sortable: true,
      template: OperatingStatusTemplate
    },
    {
      name: "countryCode",
      title: t("institutions.address"),
      template: (row: InstitutionBaseResponse) => getInstitutionAddress(row, t)
    }
  ], [language]);

  const loading = institutionPagedLoading || mergeInstitutionsLoading;

  return (
    <BaseDialog
      className="InstitutionMergeDialog" open={open} onClose={onClose} size="large" loading={loading}
      title={t("institution.merge.dialogTitle")} closeOnBackdropClick={false} closeOnEscapeKeyDown={false}
      actions={(<>
        <IconButton size="large" color="primary" onClick={onHelpClick}><HelpOutlineIcon color="primary"/></IconButton>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={closeHelpPopover}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
        >
          <div className="InstitutionMergeDialogPopover padding-left-s padding-right-s padding-top-s">
            <p>{t("institution.merge.hint1")}</p>
            <p>{t("institution.merge.hint2")}</p>
          </div>
        </Popover>
        <Button variant="outlined" size="large" onClick={onClose}>
          {t("main.close")}
        </Button>
        <Button variant="contained" size="large" onClick={showMergeToCurrentConfirmation} disabled={!selected}>
          {t("institution.merge.mergeToCurrent")}
        </Button>
        <Button variant="contained" size="large" onClick={showMergeToNewDialog} color="primary" disabled={!selected}>
          {t("institution.merge.mergeToNew")}
        </Button>
      </>)}
    >
      <SearchField
        placeholder={t("main.searchPlaceholder")} onSearch={setSearch} className="text-right margin-bottom-s"
      />
      <DataTable
        data={tableRows} columns={columns} onRowClick={onInstitutionSelect} sort={sort} onSort={onSort}
        emptyText={t("main.empty")} getRowClass={getRowClass}
      />
      <DataPagination pages={pages || 0} onChange={setPage} value={page}/>
    </BaseDialog>
  );
};

export default memo(InstitutionMergeDialog);
