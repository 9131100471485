import React, { memo, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { setInstitutionsMenuPopover, useInstitutionsMenuPopover } from "../../hooks/institution";
import { useModal } from "mui-modal-provider";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { ConfirmationDialog } from "@nbp/dnafe-material-ui/dist/components";
import PopoverMenu, { PopoverMenuItemProps } from "@nbp/dnafe-material-ui/dist/components/PopoverMenu";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import { InstitutionBaseResponse } from "../../api";
import {
  INSTITUTION_STATUS_CLOSED,
  INSTITUTION_STATUS_CLOSED_FOREVER,
  INSTITUTION_STATUS_OPEN
} from "../../constants/institution";

interface InstitutionMenuPopoverProps {
  onDelete: (id: string) => void;
  onEdit?: (data: InstitutionBaseResponse) => void;
  onOpen?: (data: InstitutionBaseResponse) => void;
  onClose?: (data: InstitutionBaseResponse) => void;
  onPermanentlyClose?: (data: InstitutionBaseResponse) => void;
}

export const InstitutionMenuPopover = ({ onDelete, onEdit, onOpen, onClose, onPermanentlyClose }: InstitutionMenuPopoverProps) => {
  const { t, i18n: { language } } = useTranslation();
  const popover = useInstitutionsMenuPopover();
  const anchor = popover?.anchor;
  const data = popover?.data;
  const { showModal } = useModal();

  const closeMenu = useEvent(() => setInstitutionsMenuPopover({ anchor: null, data }));
  useEffect(closeMenu, []);

  const deleteHandler = useEvent(() => onDelete(data.id));

  const showDeleteConfirmationDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <ConfirmationDialog
      text={t("institutions.deleteConfirmation")} confirmText={t("main.delete")}
      title={t("main.confirmation")} cancelText={t("main.cancel")} onConfirm={deleteHandler} {...props}
    />
  )));

  const menuItems = useMemo<PopoverMenuItemProps[]>(() => [
    !!onEdit && data?.operatingStatus !== INSTITUTION_STATUS_CLOSED_FOREVER && {
      text: t("main.edit"),
      onClick: onEdit
    },
    !!onOpen && data?.operatingStatus === INSTITUTION_STATUS_CLOSED && {
      text: t("institutions.openInstitution"),
      onClick: onOpen
    },
    !!onClose && data?.operatingStatus === INSTITUTION_STATUS_OPEN && {
      text: t("institutions.closeInstitution"),
      onClick: onClose
    },
    !!onPermanentlyClose && data?.operatingStatus !== INSTITUTION_STATUS_CLOSED_FOREVER && {
      text: t("institutions.permanentlyClose"),
      onClick: onPermanentlyClose
    },
    {
      text: t("main.delete"),
      onClick: showDeleteConfirmationDialog
    }
  ].filter(item => !!item), [data, language]);

  return (
    <PopoverMenu
      className="InstitutionMenuPopover"
      anchor={anchor}
      data={data}
      onClose={closeMenu}
      menuItems={menuItems}
    />
  );
};

export default memo(InstitutionMenuPopover);
