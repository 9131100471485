import { PagedTableSort } from "@nbp/dnafe-material-ui/dist/models/paged";

export const getSortValue = (sort: PagedTableSort) => {
  return sort?.field ? [sort?.field, sort?.desc ? "desc" : "asc"].join(",") : undefined;
};

export const getValueByPath = (obj: any, path: string) => path.split(".").reduce((acc, c) => {
  return !!acc && typeof acc === "object" ? acc[c] : null;
}, obj);

export const getStringSorter = (sortBy: string) => {
  return (a: any, b: any) => {
    if (!b || b[sortBy] < a[sortBy]) {
      return 1;
    }
    if (!a || b[sortBy] > a[sortBy]) {
      return -1;
    }
    return 0;
  };
};

export const getNumberSorter = (sortBy: string) => {
  return (a: any, b: any) => b[sortBy] - a[sortBy];
};
