import React, {FC, memo, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {MemberMenuPopover} from "../../hooks/institution";
import {useModal} from "mui-modal-provider";
import {useEvent} from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import {ConfirmationDialog} from "@nbp/dnafe-material-ui/dist/components";
import PopoverMenu, {PopoverMenuItemProps} from "@nbp/dnafe-material-ui/dist/components/PopoverMenu";
import {SimpleDialogProps} from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import {useCurrentUser} from "../../hooks/auth";

interface InstitutionMemberMenuPopoverProps {
  onDelete: (id: string) => void;
  onApprove: (id: string) => void;
  usePopover: () => MemberMenuPopover;
  setPopover: (popover: MemberMenuPopover) => void;
  approveConfirmation: string;
  deleteConfirmation: string;
}

export const InstitutionMemberMenuPopover: FC<InstitutionMemberMenuPopoverProps> = (
  {
    onDelete,
    onApprove,
    usePopover,
    setPopover,
    approveConfirmation,
    deleteConfirmation
  }
) => {
  const {t, i18n: {language}} = useTranslation();
  const popover = usePopover();
  const anchor = popover?.anchor;
  const data = popover?.data;
  const {showModal} = useModal();
  const currentUser = useCurrentUser();
  const currentUserEmail = currentUser?.decodedToken?.email;
  const currentUserId = currentUser?.decodedToken?.sub;

  const closeMenu = useEvent(() => setPopover({anchor: null, data}));
  useEffect(closeMenu, []);

  const deleteHandler = useEvent(() => onDelete(data.userId));
  const approveHandler = useEvent(() => onApprove(data.userId));

  const showDeleteConfirmationDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <ConfirmationDialog
      text={t(deleteConfirmation)} confirmText={t("main.remove")}
      title={t("main.confirmation")} cancelText={t("main.cancel")} onConfirm={deleteHandler} {...props}
    />
  )));

  const showApproveConfirmationDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <ConfirmationDialog
      text={t(approveConfirmation)} confirmText={t("main.approve")}
      title={t("main.confirmation")} cancelText={t("main.cancel")} onConfirm={approveHandler} {...props}
    />
  )));

  const menuItems = useMemo<PopoverMenuItemProps[]>(() => {
    const items = [
      {
        text: t("main.approve"),
        onClick: showApproveConfirmationDialog,
        disabled: data?.approvalState <= 0 || data?.email === currentUserEmail || data?.createdBy === currentUserId,
        state: data?.approvalState
      },
      {
        text: t("main.remove"),
        onClick: showDeleteConfirmationDialog
      }
    ];
    return items.filter(item => !!item);
  }, [data, language]);

  return (
    <PopoverMenu
      className="InstitutionMemberMenuPopover"
      anchor={anchor}
      data={data}
      onClose={closeMenu}
      menuItems={menuItems}
    />
  );
};

export default memo(InstitutionMemberMenuPopover);
